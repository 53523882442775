<template>
  <div>
    <div
      v-if="processing"
      style="height: 100vh; width: 100vw; padding:0;"
      class="d-flex justify-content-center align-items-center"
    >
      <b-spinner
        variant="primary"
        type="grow"
      />
    </div>
    <div v-else-if="sections">
      <!-- Start header section -->
      <section class="header-section home-one">
        <header class="header-area">
          <div class="dope-nav-container breakpoint-off">
            <div class="container">
              <div class="row">
                <!-- Main Menu -->
                <nav
                  id="dopeNav"
                  class="dope-navbar pt-2 d-flex w-100 justify-content-between"
                >

                  <!-- Logo -->
                  <a
                    class="nav-brand"
                    href="/"
                  >
                    <VuexyLogo />
                  </a>

                  <!-- Menu -->
                  <div class="dope-menu">
                    <ul>
                      <div v-if="!loggedIn">
                        <li class="nav-item">
                          <a @click="$router.push({name:'login'})">{{ $t('Login') }}</a>
                        </li>
                      </div>
                      <li
                        v-else
                        class="nav-item"
                      >
                        <div class="d-flex">
                          <a
                            class="btn px-3 rounded mr-1"
                            style="height:35px; background-color: #EC8F46; color:white; border:none;"
                            @click="pushToHome"
                          >{{ $t('Go to Dashboard') }}</a>
                          <manage-permissions
                            v-if="canManagePermissions"
                            :permissions="permissions"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </nav>
                <div class="overlay" />
              </div>
            </div>
          </div>
        </header>
        <div class="container">
          <div class="row">
            <div class="col-md-8 row header-content">
              <div
                v-if="sections.header"
                class="col-12 d-flex justify-content-center flex-column header-text-wrap"
              >
                <input
                  v-if="canUpdate"
                  v-model="sections.header.title"
                  class="form-control"
                  @change="updateSection('header')"
                >
                <h1
                  v-if="sections.header"
                  class="header-text"
                >
                  {{ sections.header.title }}
                </h1>
                <p class="header-text">
                  {{ sections.header.description }}
                </p>
                <textarea
                  v-if="canUpdate"
                  v-model="sections.header.description"
                  class="p-1"
                  @change="updateSection('header')"
                />
              </div>
            </div>
            <div class="text-right col-md-4 img-fluid">
              <img :src="sections.header && getStorage(sections.header.image)">
              <input
                v-if="canUpdate"
                ref="headerFileUploader"
                type="file"
                hidden
                @change="(e)=>{
                  if (!e.target.files[0]) return
                  headerImage = e.target.files[0]
                  updateSection('header')
                }"
              >
              <b-button
                v-if="canUpdate"
                class="my-2"
                variant="success"
                @click="$refs.headerFileUploader.click()"
              >
                Change Image
              </b-button>
            </div>
          </div>
        </div>
      </section>
      <!-- End header section -->

      <!-- ServiceSection -->
      <service-section
        :service-section="sections.ourServices"
        :can-update="canUpdate"
        @sectionUpdated="getAllSections()"
      />
      <!-- End of Service Section -->

      <!-- Screenshot tab section -->
      <screenshot-tabs-section :can-update="canUpdate" />
      <!-- End of screenshot tab section -->

      <section
        class="info-section section-gap-bottom home-one py-3"
        data-aos="zoom-in"
        data-aos-duration="1500"
      >
        <div
          v-if="sections.whoWeAre"
          class="container-fluid"
        >
          <div class="text-center">
            <h1
              v-if="sections.whoWeAre"
              class="service-title"
            >
              {{ sections.whoWeAre.title }}
            </h1>
            <input
              v-if="canUpdate"
              v-model="sections.whoWeAre.title"
              class="form-control"
              @change=" updateSection('whoWeAre')"
            >
          </div>
          <div class="row d-flex align-items-center p-2">
            <div class="col-lg-6 col-md-12 info-left text-center">
              <img
                class="w-100"
                :src="sections.whoWeAre && getStorage(sections.whoWeAre.image)"
              >
              <input
                v-if="canUpdate"
                ref="whoWeAreFileUploader"
                type="file"
                hidden
                @change="(e)=>{
                  if (!e.target.files[0]) return
                  whoWeAreImage = e.target.files[0]
                  updateSection('whoWeAre')
                }"
              >
              <b-button
                v-if="canUpdate"
                class="my-2"
                variant="success"
                @click="$refs.whoWeAreFileUploader.click()"
              >
                Change Image
              </b-button>
            </div>
            <div class="col-lg-6 col-md-12 info-right pt-2">
              <h6 class="text-bold text-center-sm line-height-1-5">
                {{ sections.whoWeAre.description }}
              </h6>
              <textarea
                v-if="canUpdate"
                v-model="sections.whoWeAre.description"
                class="p-1 w-100"
                @change=" updateSection('whoWeAre')"
              />
              <who-we-are-section :can-update="canUpdate" />
            </div>
          </div>
        </div>
      </section>

      <carousel-section :can-update="canUpdate" />

      <section
        class="py-3 result-section"
        data-aos="fade-in"
        data-aos-duration="1500"
      >
        <div class="container">
          <div class="text-center">
            <h1
              v-if="sections.ourResults"
              class="service-title"
            >
              {{ sections.ourResults.title }}
            </h1>
            <input
              v-if="canUpdate"
              v-model="sections.ourResults.title"
              class="form-control"
              @change=" updateSection('ourResults')"
            >
            <p
              v-if="sections.ourResults"
              class="pt-1"
            >
              {{ sections.ourResults.description }}
            </p>
            <textarea
              v-if="canUpdate"
              v-model="sections.ourResults.description"
              class="p-1 w-100"
              @change=" updateSection('ourResults')"
            />
          </div>
          <result-section :can-update="canUpdate" />
        </div>
      </section>

      <review-section
        :review-section="sections.testimonials"
        :can-update="canUpdate"
        @sectionUpdated="getAllSections()"
      />

      <!-- <pricing-plan /> -->

      <footer class="footer ">
        <div class="container">

          <div class="row">
            <div class="col-md-6 col-lg-4">
              <vuexy-logo />
            </div>
            <div class="col-md-6 col-lg-4">
            <!-- <h6>Extra Links</h6>
            <ul>
              <li class="nav-item">
                <a>Terms & Conditions</a>
              </li>
              <li class="nav-item">
                <a>Privacy and Policies</a>
              </li>

            </ul> -->
            </div>
            <div class="col-md-6 col-lg-4">
              <h6>
                Contact Us:
              </h6>
              <ul>
                <!-- <li>
                <feather-icon
                  icon="MapPinIcon"
                  size="18"
                />
                USA
              </li> -->
                <!-- <li>
                <feather-icon
                  icon="PhoneIcon"
                  size="18"
                />
                +91 9384909389000
              </li> -->
                <li>
                  <a
                    href="mailto:support@workzone1.com"
                    target="__blank"
                  >
                    <feather-icon
                      icon="MailIcon"
                      size="18"
                    />
                    support@workzone1.com
                  </a>
                </li>
              </ul>

            </div>

          </div>
        </div>

      </footer>
      <div class=" bg-workzone p-1 d-flex justify-content-center align-items-center">
        <h4 class="text-white">
          &copy; {{ currentYear }} Workzone1
        </h4>

      </div>
    </div>

    <!-- <div v-else>
      <something-wrong />
    </div> -->

  </div>
</template>
<script>
import moment from 'moment'
import { isUserLoggedIn, getUserData } from '@/auth/utils'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import AOS from 'aos'
import useApollo from '@/plugins/graphql/useApollo'
import 'aos/dist/aos.css'
import { checkPermission } from '@/utils/permissions'
import { BButton, BSpinner } from 'bootstrap-vue'
import _ from 'lodash'
import { mapPermissionSlug } from '@/const/common'
import ServiceSection from './common/components/ServiceSection.vue'
// import PricingPlan from './common/components/PricingPlan.vue'
import CarouselSection from './common/components/splash/CarouselSection.vue'
import ReviewSection from './common/components/splash/ReviewSection.vue'
import WhoWeAreSection from './common/components/splash/WhoWeAreSection.vue'
import ResultSection from './common/components/splash/ResultSection.vue'
import ManagePermissions from './common/components/ManagePermissions.vue'
// import SomethingWrong from './error/SomethingWrong.vue'
import ScreenshotTabsSection from './common/components/splash/ScreenshotTabsSection.vue'

export default {
  components: {
    VuexyLogo,
    ResultSection,
    ServiceSection,
    // PricingPlan,
    CarouselSection,
    ReviewSection,
    WhoWeAreSection,
    ManagePermissions,
    // SomethingWrong,
    BButton,
    ScreenshotTabsSection,
    BSpinner,
  },
  data() {
    return {
      self: getUserData(),
      canUpdate: false,
      canManagePermissions: this.$store.state.permissions.canManagePermissions.value,
      permissions: ['cms.update'],
      sections: {
        header: {},
        whoWeAre: {},
        ourResults: {},
        testimonials: {},
        ourServices: {},
      },
      processing: false,
      headerImage: null,
      whoWeAreImage: null,
    }
  },
  computed: {
    loggedIn() {
      return isUserLoggedIn()
    },
    currentYear() {
      return moment().year()
    },
  },
  mounted() {
    AOS.init()
    this.getAllSections()

    if (this.self && this.self.uuid) {
      // eslint-disable-next-line no-restricted-globals
      onload = () => {
        this.refetchPermission()
        this.$store.dispatch('project/STORE_ALL_PERMISSIONS')
      }

      checkPermission(this.permissions, permissions => {
        this.canUpdate = permissions['cms.update'] === true
      })
    }
  },
  methods: {
    pushToHome() {
      this.$router.push({ name: 'home' }).then(() => {
        window.location.reload()
      })
    },
    refetchPermission() {
      checkPermission(this.permissions, permissions => {
        const userPermissions = Object.entries(permissions).map(([key, value]) => ({ key: mapPermissionSlug[key], value }))
        this.$store.dispatch('permissions/setPermissions', userPermissions)
      })
    },

    getAllSections() {
      this.processing = true
      useApollo.cms.getAllSections().then(res => {
        const { cmsSections } = res.data
        cmsSections.forEach(section => {
          this.sections[_.camelCase(section.section)] = section
        })
      }).finally(() => {
        this.processing = false
      })
    },
    camelToFlat(camel) {
      const camelCase = camel.replace(/([a-z])([A-Z])/g, '$1 $2')

      return camelCase.toLowerCase()
    },

    updateSection(type) {
      this.$forceUpdate()
      const { id } = this.sections[type]
      const projectUid = this.$store.state.project.selectedProject
      const formData = {
        title: this.sections[type]?.title,
        description: this.sections[type]?.description,
        section: this.camelToFlat(type),
      }

      if (type === 'header' && this.headerImage) {
        formData.image = this.headerImage
      }
      if (type === 'whoWeAre' && this.whoWeAreImage) {
        formData.image = this.whoWeAreImage
      }
      useApollo.cms.updateSection({ id, projectUid, input: formData }).then(() => {
        this.getAllSections()
      })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@core/scss/base/pages/splash-screen.scss';
</style>
